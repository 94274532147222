import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Col, Row } from 'react-bootstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import SideBar from '../components/sidebar'
import { ContactForm, Name, Email } from '../components/Forms'
import ReCAPTCHA from 'react-google-recaptcha'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import IndexSEOImage from '../images/wood-roof-construction.jpg'
import contactBanner from '../images/home-banner.jpg'

class Contact extends React.Component {
    constructor(props) {
        super(props)

        this.handleUpdate = this.handleUpdate.bind(this)

        this.state = {
            name: ``,
            email: ``,
            phone_number: ``,
            error: ``,
            loading: false,
        }
    }
    
    handleUpdate = event => {
        if (event.target.name === 'name') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'email') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'subject') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'phone_number') {
            this.setState({
                [event.target.name]: event.target.value.replace(/\D/g, ''),
                error: '',
            })
        }
        this.setState({
            [event.target.name]: event.target.value,
            error: '',
        })
    }

    onChange(value) {
        console.log('Captcha Value:', value);
    }

    render() {
        return (
            <StaticQuery
            query={graphql`
                query ContactMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='contact'>
                    <SEO title='Contact Us' description={``} image={IndexSEOImage} keywords={[``]} />
                    <Banner title='Contact Us' image={contactBanner} subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' />
                    <section>
                        <Container>
                            <Row>
                                <Col xs='12' sm='12' md='8' lg='8'>
                                    <ContactForm title='Contact Us' subtitle='We look forward to professionally working with you to solve your construction needs.' error={this.state.error}>
                                        <Name handleUpdate={this.handleUpdate} name={this.state.name} autoComplete='on' />
                                        <Email handleUpdate={this.handleUpdate} email={this.state.email} autoComplete='on' />
                                        <fieldset>
                                            <FontAwesomeIcon className='formIcon' icon='pen-nib' />
                                            <input name='subject' placeholder='Subject:' type='text' onChange={this.handleUpdate} value={this.state.subject || ''} required />
                                        </fieldset>
                                        <fieldset>
                                            <textarea name='message' placeholder='Type your message here...' required></textarea>
                                        </fieldset>
                                        <ReCAPTCHA sitekey={data.site.siteMetadata.google.googleReCaptchaKey} onChange={this.onChange} />
                                        <div className='spacer20'></div>
                                        <button type="submit" className="btn btn-secondary btn-block center" disabled={this.state.loading}>
                                        {this.state.loading ? null : 'Send'}
                                        {this.state.loading && (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                        )}
                                        </button>
                                    </ContactForm>

                                    <div className='spacer50'></div>
                                </Col>
                                <SideBar />
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default Contact
